  <template>
  <v-container fluid id="ObraConsulta" class="pa-0">
    <!-- Cabeçalho do Conteudo -->
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-2 ml-4">
        Consulta de Ordem de Compra
      </v-toolbar-title>

      <!-- Botões - Filtro / Novo / Opções -->
      <v-toolbar-title class="mt-0 mb-6 mr-4 ml-4 pa-0" dark elevation="0">
        <div class="d-flex" tabindex="-1">
          <v-container
            class="pa-0 ma-0 mb-7 d-flex"
            style="margin-top: -4px; max-width: 1200px"
          >
            <!-- Input Filtro de busca - Chips / Formulario -->
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation="0"
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
              <template v-slot:activator="{ on }">
                <!-- Input do Filtro -->
                <div
                  ref="vMenuDiv"
                  class="false-filter d-flex align-center"
                  align="center"
                  v-on="on"
                >
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF">
                    mdi-magnify
                  </v-icon>
                  <!-- Div de todos os chips selecionados -->
                  <div class="false-text flex-wrap">
                    <!-- Chip cod_cotacao store_OrdemCompra -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.cod_cotacao"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Código:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.cod_cotacao
                      }}</strong>
                    </v-chip>
                    <!-- Chip nome_cotacao-->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.nome_cotacao"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Cotação:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.nome_cotacao
                      }}</strong>
                    </v-chip>
                    <!-- Chip responsavel-->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.responsavel"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Responsável:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.responsavel
                      }}</strong>
                    </v-chip>

                    <!-- Chip fornecedor -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.fornecedor"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Fornecedor:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.fornecedor
                      }}</strong>
                    </v-chip>

                    <!-- Chip Locais -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.obra_dep"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Locais:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.obra_dep
                      }}</strong>
                    </v-chip>

                    <!-- Chip De (valor total) -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.de_valor_total"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >De valor total:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.de_valor_total
                      }}</strong>
                    </v-chip>

                    <!-- Chip Até (valor total) -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.ate_valor_total"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Até valor total:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_OrdemCompra.filtro.ate_valor_total
                      }}</strong>
                    </v-chip>

                    <!-- Chip Data dt_cotacao_de -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.dt_criacao_de"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >De data cotação:&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{
                          store_OrdemCompra.filtro.dt_criacao_de
                            ? formatDate(store_OrdemCompra.filtro.dt_criacao_de)
                            : ""
                        }}
                      </strong>
                    </v-chip>
                    <!-- Chip dt_cotacao_ate -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.dt_criacao_ate"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Até data cotação:&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{
                          store_OrdemCompra.filtro.dt_criacao_ate
                            ? formatDate(
                                store_OrdemCompra.filtro.dt_criacao_ate
                              )
                            : ""
                        }}
                      </strong>
                    </v-chip>
                    <!-- Chip dt_vencimento_de -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.dt_vencimento_de"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >De data vencimento:&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{
                          store_OrdemCompra.filtro.dt_vencimento_de
                            ? formatDate(
                                store_OrdemCompra.filtro.dt_vencimento_de
                              )
                            : ""
                        }}
                      </strong>
                    </v-chip>
                    <!-- Chip dt_vencimento_ate -->
                    <v-chip
                      v-if="store_OrdemCompra.filtro.dt_vencimento_ate"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Até data vencimento:&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{
                          store_OrdemCompra.filtro.dt_vencimento_ate
                            ? formatDate(
                                store_OrdemCompra.filtro.dt_vencimento_ate
                              )
                            : ""
                        }}
                      </strong>
                    </v-chip>
                  </div>

                  <v-icon class="false-icon ml-2 mr-4" color="#FFF">
                    mdi-filter-variant-plus
                  </v-icon>
                </div>
              </template>
              <!-- Formulario do Filtro -->
              <v-container
                :style="{ 'background-color': COR_SECUNDARIA_2 }"
                style="
                  min-width: 800px !important;
                  max-width: 1200px !important;
                  margin-bottom: -34px;
                "
              >
                <!-- 1ª Linha do Formulario de busca/Filtro -->
                <v-row no-gutters class="linhas mb-n2">
                  <!-- Input Cód. cotação (código da cotação) -->
                  <v-col md="2">
                    <v-text-field
                      id="txtCodigos"
                      ref="txtCodigo"
                      dense
                      v-model="filtro_local.cod_cotacao"
                      cache-items
                      flat
                      label="Cód. cotação"
                      placeholder="Cód. cotação"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="100"
                      clearable
                      tabindex="0"
                      class="mr-2"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input nome_cotacao -->
                  <v-col md="10">
                    <v-text-field
                      v-model="filtro_local.nome_cotacao"
                      item-text="nome_cotacao"
                      item-value="nome_cotacao"
                      label="Nome cotação"
                      placeholder="Nome cotação"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 2ª Linha do Formulario de busca/Filtro -->
                <v-row no-gutters class="linhas mb-n2">
                  <!-- input responsavel -->
                  <v-col md="6">
                    <v-text-field
                      v-model="filtro_local.responsavel"
                      item-text="responsavel"
                      item-value="responsavel"
                      label="Responsável"
                      placeholder="Responsável"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- input fornecedor -->
                  <v-col md="6">
                    <v-text-field
                      v-model="filtro_local.fornecedor"
                      item-text="fornecedor"
                      item-value="fornecedor"
                      label="Fornecedor"
                      placeholder="Fornecedor"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!--  Input Situação -->
                  <!-- Rogério comentou para deixar como referência o select Situação -->
                  <!-- <v-col md="4">
                    <v-autocomplete
                      :items="situacao"
                      v-model="filtro_local.situacao"
                      item-text="situacao"
                      item-value="situacao"
                      label="Situação"
                      placeholder="Situação"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                      maxlength="50"
                    >
                    </v-autocomplete>
                  </v-col> -->
                </v-row>
                <!-- 3ª Linha do Formulario de busca/Filtro -->
                <v-row no-gutters class="linhas mb-n2">
                  <!-- input Locais obra_dep -->
                  <v-col md="6">
                    <v-text-field
                      v-model="filtro_local.obra_dep"
                      item-text="obra_dep"
                      item-value="obra_dep"
                      label="Locais"
                      placeholder="Locais"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>                  
                  <!-- input De (valor total) -->
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.de_valor_total"
                      item-text=""
                      item-value=""
                      label="De (valor total)"
                      placeholder="De (valor total)"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- input Até (valor total) -->
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.ate_valor_total"
                      item-text=""
                      item-value=""
                      label="Até (valor total)"
                      placeholder="Até (valor total)"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 4ª Linha do Formulario -->
                <v-row no-gutters class="linhas mb-n2">
                  <!-- Input De (data compra)  -->
                  <v-col md="3">
                    <DatePicker
                      id=""
                      ref=""
                      v-model="filtro_local.dt_criacao_de"
                      label="De (data compra)"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      format="DD/MM/YYYY"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                  <!-- Input Até (data compra)  -->
                  <v-col md="3">
                    <DatePicker
                      id=""
                      ref=""
                      v-model="filtro_local.dt_criacao_ate"
                      label="Até (data compra)"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      format="DD/MM/YYYY"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                  <!-- Input De (data entrega)  -->
                  <v-col md="3">
                    <DatePicker
                      id=""
                      ref=""
                      v-model="filtro_local.dt_vencimento_de"
                      label="De (data entrega)"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      format="DD/MM/YYYY"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                  <!-- Input Até (data entrega)  -->
                  <v-col md="3">
                    <DatePicker
                      id=""
                      ref=""
                      v-model="filtro_local.dt_vencimento_ate"
                      label="Até (data entrega)"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      format="DD/MM/YYYY"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                </v-row>
                <!-- 3ª Linha do Formulario -->
                <v-row no-gutters class="linhas"> </v-row>
              </v-container>

              <!-- Cria uma linha de divisoria -->
              <p></p>
              <v-divider></v-divider>

              <!-- Container Botões - Limpar / Consultar -->
              <div
                class="pa-4 d-flex justify-space-between"
                v-bind:style="{ 'background-color': COR_SECUNDARIA }"
              >
                <!-- Botão Limpar Todos -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-close
                  </v-icon>
                  <span>Limpar Todos</span>
                </v-btn>

                <!-- Botao Consultar -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="busca(1)"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-magnify
                  </v-icon>
                  <span>Consultar</span>
                </v-btn>
              </div>
            </v-menu>
          </v-container>

          <!-- Espaçamento entre os elementos -->
          <v-spacer></v-spacer>

          <!-- Botão Novo --------------------------------->
          <router-link
            :to="{ name: 'CotacaoContainerNovo' }"
            class="nav-link"
            aria-current="page"
            tabindex="-1"
          >
            <v-btn
              class="text-white text-capitalize"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn>
          </router-link>
        </div>
      </v-toolbar-title>
    </v-toolbar-title>

    <!-- Tabela -->
    <v-row class="mt-n11 mx-0">
      <!-- Grade  -->
      <v-col cols="12" class="pa-0 mx-0">
        <v-container
          class="container-principal container-rounded rounded-lg mx-0 px-0"
        >
          <!-- Conteiner -------------------------------------- -->
          <v-data-table
            id="virtualScrollTable"
            ref="virtualScrollTable"
            :items="ordemcomprateste"
            :headers="headers"
            :loading="loading"
            :height="tableHeight"
            :items-per-page="20"
            :hide-default-footer="true"
            :hide-default-header="isMobile"
            :page="currentPage || 1"
            :server-items-length="store_OrdemCompra.count_dados"
            fixed-header
            dense
            style="white-space: nowrap"
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            class="data-table"
          >
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template #item="{ item }">
              <tr v-if="!isMobile"  >
                <!-- Botão de Ações -->
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        style="width: 3%"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <!-- Lista de Ações -->
                    <v-list class="py-0">
                      <v-list-item
                        v-for="(opcao, i) in items"
                        :key="i"
                        @click="() => {}"
                        class="px-2"
                      >
                        <!-- Ação de Editar -->
                        <v-list-item-title v-if="opcao.title == 'Editar'">
                          <router-link
                            :to="'/cotacaocontainer/' + item.cod_cotacao"
                            class="nav-link d-flex justifi-center align-center mt-2 ml-1"
                            aria-current="page"
                            style="white-space: normal"
                          >
                            <v-icon color="green" class="mr-2 icon-menu">
                              mdi-pencil
                            </v-icon>
                            {{ opcao.title }}
                          </router-link>
                        </v-list-item-title>

                        <!-- Ação de Excluir -->
                        <v-list-item-title
                          v-else-if="opcao.title == 'Excluir'"
                          @click="ModalConfirmacao(item)"
                        >
                          <v-icon color="red" class="mr-2 icon-menu">
                            mdi-delete
                          </v-icon>
                          {{ opcao.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

<!-- Tabela da tela inicial que será carregada quando a página abrir -->
                <!-- Campo dt_compra -->
                <td style="width: 3%; color: #808080">
                  {{ item.dt_compra }}
                  <!-- {{ item.dt_entrega ? formatDate(item.dt_entrega) : "" }} -->
                </td>

                <!-- Campo cod_cotacao -->
                <td class="py-7">
                  <router-link
                    :to="'/cotacaocontainer/' + item.cod_cotacao"
                    class="nav-link d-flex"
                    aria-current="page"
                    style="width: 110%; white-space: normal; color: #808080"
                  >
                    {{ item.cod_cotacao }} - {{ item.nome_cotacao }}
                  </router-link>
                </td>

                <!-- Campo responsavel -->
                <td style="width: 3%; color: #808080">
                  {{ item.responsavel }}
                </td>

                <!-- Campo fornecedor -->
                <td style="width: 3%; color: #808080">
                  {{ item.fornecedor }}
                </td>

                <!-- Campo Locais obra_dep -->
                <td style="width: 2%; font-size: 12px; color: #808080 !important"
                >
                  <span class="d-flex flex-column">
                    <span class="locais_obra_dep" v-for="item in item.obra_dep" :key="item">
                      {{ item.length > 20 ? item.substring(0, 20) + '...' : item }}
                      </span>
                  </span>
                </td>

                <!-- Campo dt_entrega -->
               <td style="width: 3%; color: #808080">
                  {{ item.dt_entrega }}
                   <!-- {{ item.dt_entrega ? formatDate(item.dt_entrega) : "" }} -->
                </td>

                <!-- Campo valor_total -->
                <td 
                  style="width: 3%; color: #808080">
                  {{ formatNumber(item.valor_total) }}
              </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
      <!-- Fim Grade --------------------------------- -->
    </v-row>

    <!-- dialog -->

    <!-- Paginacao -->
    <div
      class="paginacao d-flex justify-end align-center"
      style="background-color: #ffffff"
    >
      <span style="color: grey; font-size: 14px" class="mr-3"
        >{{ store_OrdemCompra.count_dados }} linhas</span
      >
      <v-pagination
        id="pagination"
        ref="pagination"
        v-model="currentPage"
        :length="paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
        class="mt-n1"
        style="color: grey"
      />
    </div>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog
        v-model="dialogCancelar"
        transition="dialog-bottom-transition"
        max-width="410"
        class="pa-0"
      >
        <v-card elevation="0" class="">
          <v-card-title
            v-bind:style="{ 'background-color': COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3"
          >
            <span class="text-white title-page body-1"></span>
            <v-btn @click="dialogCancelar = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title class="text-h6 custom-card-title">
            <p>Deseja realmente excluir esta cotação?</p>
            <p>
              Atenção: Todos os dados serão permanentemente apagados. Essa ação
              não pode ser desfeita.
            </p>
            <p>Por favor, confirme se deseja prosseguir.</p>
          </v-card-title>
          <v-card-text>
            <v-container class="mt-0 px-0">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-form ref="form" v-model="valid">
                    <v-textarea
                      rows="1"
                      auto-grow
                      counter="200"
                      v-model="container.message"
                      :rules="[rules.required]"
                      class="input-razao mb-0"
                      loader-height="1"
                      autofocus
                      background-color="#FFF"
                      label="Motivo da exclusão:"
                      placeholder="Motivo da exclusão:"
                      light
                      filled
                      outlined
                      required
                      clearable
                      dense
                    ></v-textarea>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              class="mr-4"
              color="primary"
              text
              @click="dialogCancelar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :disabled="!valid"
              :loading="loading"
              @click="deletaCotacao()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import store_usuario from "../../../store/store_usuario";

import store_OrdemCompra from "./store_OrdemCompra";

import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
} from "../../../services/constantes";

import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "@/components/ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";

export default {
  name: "ObraConsulta",
  components: {
    DatePicker,
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_OrdemCompra: store_OrdemCompra,
      formatDate: formatDate,
      formatNumber: formatNumber,

      loading: false,
      search: null,
      valid: false,
      search_input_focus: false,
      menu: false,
      array_obras: null,
      loading_excluir: false,
      filtro: null,
      currentPage: 1,

      /* Container  */
      container: {
        message: "",
      },

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_SECUNDARIA_2: COR_SECUNDARIA_2,
      COR_SUBTITULO: COR_SUBTITULO,
      dialogCancelar: false,
      count_dados: null,
      paginas: null,

      situacao: [
        "Em preenchimento",
        "Aguardando retorno",
        "Aguardando análise",
        "Pedido concluído",
      ],

      // Filtro de busca
      filtro_local: {
        cod_cotacao: null,
        nome_cotacao: null,
        responsavel: null, 
        fornecedor: null,
        obra_dep: null,
        de_valor_total: null,
        ate_valor_total: null,

        dt_criacao_de: null,
        dt_criacao_ate: null,
        dt_vencimento_de: null,
        dt_vencimento_ate: null,
      },

      data_picker: {
        "min-width": "150px !important",
      },

      rules: {
        required: (v) => !!v || "Preencha o motivo para continuar",
      },

      /* Cabeçalho da Tabela */
      headers: [
        { text: "", value: "", sortable: false },
        { text: "Data da compra", value: "dt_compra", sortable: true },
        { text: "Código e nome da cotação", value: "cod_cotacao", sortable: true },
        { text: "Responsável", value: "responsavel", sortable: true },
        { text: "Fornecedor", value: "fornecedor", sortable: true },
        { text: "Locais", value: "obra_dep", sortable: true },
        { text: "Data da entrega", value: "dt_entrega", sortable: true },
        { text: "Valor total", value: "valor_total", sortable: true },
      ],

// Dados fictícios para teste
      ordemcomprateste: [
        {
          cod_ordemcompra: 7,
          dt_compra: "01/09/2024",
          cod_cotacao: 123,
          nome_cotacao: "Cimento para a fundação",
          responsavel: "José Pereira da Silva",
          fornecedor: "Hidromar materiais de construção Ltda.",
          obra_dep: ["Obra Residência Condomínio Max", "Depósito Vila Santa Cruz", "Obra Loja Av. Pres. Vargas"],
          dt_entrega: "06/09/2024",
          valor_total: 27304.45
        },
        {
          cod_ordemcompra: 18,
          dt_compra: "10/08/2024",
          cod_cotacao: 325,
          nome_cotacao: "Tinta para o teto",
          responsavel: "Maria Junqueira",
          fornecedor: "Tintas Max Ltda.",
          obra_dep: ["Depósito Vila Aparecida", "Obra Residência Morada do Verde"],
          dt_entrega: "15/08/2024",
          valor_total: 12430.87
        },
        {
          cod_ordemcompra: 27,
          dt_compra: "23/08/2024",
          cod_cotacao: 965,
          nome_cotacao: "Areia para o concreto",
          responsavel: "Pedro de Oliveira",
          fornecedor: "Top Materiais de Construção Ltda.",
          obra_dep: ["Obra Loja Av. Major Nicácio"],
          dt_entrega: "28/08/2024",
          valor_total: 5467.36
        }
      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
    };
  },

  async created() {
    console.log("teste");
  },

  async mounted() {},

  watch: {
    search(val) {
      // console.log('WATCH', val)
      this.currentPage = 1;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
      if (this.isMobile) return window.innerHeight - 149 + 30 - 90;
      else return window.innerHeight - 149 - 55;
    },
  },

  methods: {
    ModalConfirmacao(item) {
      this.store_OrdemCompra.cotacao_selecionada = item;
      this.dialogCancelar = true;
    },
    async deletaCotacao() {
      var lo_params = {
        cod_cotacao: this.store_OrdemCompra.cotacao_selecionada.cod_cotacao,
      };
      var lo_params = {
        cod_cotacao: this.store_OrdemCompra.cotacao_selecionada.cod_cotacao,
      };
      const lo_Res = await this.store_OrdemCompra.CotacaoDelete(lo_params);
      this.dialogCancelar = false;
      this.busca();
      return lo_Res;
    },

    handlePageChange(value) {
      this.currentPage = value;
    },

    limpa_todos() {
      for (let prob in this.filtro_local) {
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null;
      }
      this.filtro_local.valor_previsto_max = ``;
      this.store_OrdemCompra.filtro = {};
    },

    barra_porcentagem(index) {
      const valorRealizado =
        this.store_OrdemCompra.dados[index].valor_realizado;
      const valorPrevisto = this.store_OrdemCompra.dados[index].valor_previsto;
      let porcentagem = Math.round((valorRealizado / valorPrevisto) * 100, 2);
      return porcentagem;
    },

    async busca() {
      this.loading = true;
      this.menu = false;
      //Opções de busca do Filtro
      this.store_OrdemCompra.filtro = { ...this.filtro_local };
      var lo_params = { ...this.store_OrdemCompra.filtro };
      //  console.log("Parâmetros de busca: ", lo_params)
      let lo_Res;
      lo_Res = await this.store_OrdemCompra.CotacoesGet(lo_params);
      // console.log("Resultado da busca: ", lo_Res)
      this.loading = false;
    },

    abriuConsulta(val) {
      // console.log( 'abriuConsulta', this.dados_editar)
      console.log("abriuConsulta", this.filtro_local);
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = { ...this.store_OrdemCompra.filtro };
        });
      }
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

.false-filter {
  background-color: #6c87a2;
  height: 38px !important;
  min-width: 400px !important;
  /* max-width: 900px!important; */
  border-radius: 5px;
  justify-content: space-between;
}

.false-text {
  width: calc(100%);
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #c9cbca;
  cursor: pointer;
  overflow-x: hidden;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.status_concluido {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_aguardando {
  font-size: 14px !important;
  background: blaze-orange;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_preenchimento {
  font-size: 14px !important;
  background: #808080;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.width-adress {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

.customer {
  display: block;
  font-weight: 600;
}

.email {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
  opacity: 0.8;
}

.custom-card-title p {
  word-break: break-word; /* Evita que palavras sejam quebradas de forma estranha */
  white-space: normal; /* Garante que o texto quebre linhas naturalmente */
}

.v-btn {
  display: inline-block;
}

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.search-input,
.search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

@media (max-width: 1024px) {
  .nav-link {
    padding-right: 0px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #fff !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  .container-principal {
    max-width: 100% !important;
  }
}

.v-menu__content {
  z-index: 10 !important;
}

/* Verificar com o Fernando */
.cor-subtitulo {
  color: var(--COR_SUBTITULO);
}

.locais_obra_dep {
  font-size: 12px !important;
  background: #469c47;
  color: #fff;
  padding: 3px 6px;
  border-radius: 25px;
  height: 24px;
  margin: 2px;
}
</style>