import Vue from "vue";
import { API } from "../../../services/API"

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    cotacao_selecionada: {},
    cotacao_edicao: {},
    obra_selecionada: {}, // Obra selecionada para a inclusão de itens na cotação

    currentPage: 1,
    count_dados: 22,
    // 12/9/24 Rogério alterou o count_dados para 22 para puxar os dados fictícios do ordemcomprateste
    // no OrdemCompraConsulta. Quando for puxar os dados com o getAll, colocar o count_dados como null.

    // Filtro na Tela de Consulta 
    filtro: {
      cod_cotacao: null,
      nome_cotacao: null,
      vinculo: null,
      situacao: null,
      dt_cotacao_de: null,
      dt_cotacao_ate: null,
      dt_validade_de: null,
      dt_validade_ate: null,
    },
    // Abas do container 
    abas: [
      ['mdi-text-box-outline', 'Dados', '0'],
      ['mdi-book-search', 'Mapa de Cotação', '0'], // mdi-book-plus-multiple
      ['mdi-cash-multiple', 'Formato de Pagamento', '0'],
    ],
  }
}

var vm_store_OrdemCompra = new Vue({

  data() {
    return initialState();
  },

  methods: {
    resetData() {
      Object.assign(this.$data, initialState());
    },

    // Métodos de Cotação - Gets / Post / Put / Delete
    // Método - Get All
    async CotacoesGet(p_params) {
      const resp = await API.get("/obras/cotacoes", { params: { ...p_params } });
      if (resp) {
        this.dados = [];
        if (resp.data.result != 'Não possui dados') {
          this.dados = resp.data.result
          this.count_dados = resp.data.result.length;
          return resp.data.result
        }
      }
    },
    // Método - Get Individual
    async CotacaoGet(p_params) {
      const resp = await API.get(`/obras/cotacao`, { params: { ...p_params } });
      return resp.data.result;
    },
    // Método - Post 
    async CotacaoPost(p_params) {
      const resp = await API.post(`/obras/cotacao`, { params: { ...p_params } });
      return resp;
    },
    // Método - Put
    async CotacaoPut(p_params) {
      const resp = await API.put(`/obras/cotacao`, { p_params });
      return resp;
    },
    // Método - Delete
    async CotacaoDelete(p_params) {
      console.log('CotacaoDelete', p_params)
      const resp = await API.delete(`/obras/cotacao`, { params: { ...p_params } });
      return resp;
    },




    // Métodos da Obra - Gets / Post / Put / Delete
    // Método - Get All
    // async ObrasGet(p_params) {
    //   const resposta = await API.get("/obras", { params: { ...p_params } });
    //   if (resposta) {
    //     this.dados = [];
    //     if (resposta.data.result != 'Não possui dados') {
    //       this.dados = resposta.data.result
    //       this.count_dados = resposta.data.result.length;
    //       return resposta.data.result
    //     }
    //   }
    // },



  },

  watch: {
    // docDocumentos()           { this.atualiza_abas() },
    // docContrato()             { this.atualiza_abas() },
    // docTransferencia()        { this.atualiza_abas() },
    // docRenogociacao()         { this.atualiza_abas() },
    // docRescisao()             { this.atualiza_abas() },
    // docAutorizacaoEscritura() { this.atualiza_abas() },
    // docEscritura()            { this.atualiza_abas() },
    // docMensagens()            { this.atualiza_abas() }
  },
})

export default vm_store_OrdemCompra